<template>
  <a-spin :spinning="spinning">
    <page-header title="意见反馈列表"></page-header>
    <a-card :bordered="false">
      <!-- 按钮 -->
      <a-row :gutter="8" class="btn--container">
        <a-col class="gutter-row" :span="8"> </a-col>
        <a-col class="gutter-row" :span="6"> </a-col>
        <a-col class="gutter-row" :span="6">
          <a-input
            v-model="filterText"
            :placeholder="l('请输入搜索内容')"
            allow-clear
          />
        </a-col>
        <a-col class="gutter-row" style="float: right" :span="4">
          <a-button type="primary" @click="search" v-if="isGranted('Pages.LGModul.Suggest.Query')">
            {{ l("Search") }}
          </a-button>
          <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
        </a-col>
      </a-row>
      <!-- <a-row> -->
      <!-- table -->
      <list-table
        :table-data="tableData"
        :total-items="totalItems"
        :tableKey="tableKey"
        :showTotal="showTotalFun"
        :actions-type="actionsType"
        @emitPreview="previewItem"
        @emitRefreshData="getData"
        @emitSortData="updateSortData"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      >
      </list-table>
      <!-- </a-row> -->
    </a-card>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import { FeedbackServiceProxy } from "./services/feedback-proxies";
import moment from "moment";
import CreateEdit from "./create-edit/create-edit";
import { AppConsts } from "@/abpPro/AppConsts";
import { ListTable } from "@/components";

export default {
  mixins: [AppComponentBase],
  name: "feedback-index",
  components: { ListTable },
  data() {
    return {
      spinning: false,
      selectedRowKeys: [],
      tableData: [],
      tableKey: "PersonnelFeedbackPagedDto",
      // 搜索
      filterText: "",
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      request: { maxResultCount: 30, skipCount: 0 },
      _serviceProxy: "",
      commonService: null,
      actionsType: [],
    };
  },
  created() {
    this._serviceProxy = new FeedbackServiceProxy(this.$apiUrl, this.$api);
    this.getData();
    this.initActionsType();
  },
  methods: {
    search() {
      this.pageNumber = 1
      this.request.skipCount = 0
      this.getData()
    },
    /**
     * 获取数据
     */
    async getData() {
      this.spinning = true;
      try {
        this._serviceProxy
          .getPaged(
            this.filterText,
            "",
            this.request.maxResultCount,
            this.request.skipCount
          )
          .finally(() => {
            this.spinning = false;
          })
          .then((result) => {
            this.tableData = result.items.map((o) => {
              o.images = AppConsts.uploadBaseUrl + o.images;
              return {
                ...o,
              };
            });
            this.totalItems = result.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.request.maxResultCount + 1,
              this.pageNumber * this.request.maxResultCount,
            ];
            this.totalPages = Math.ceil(
              result.totalCount / this.request.maxResultCount
            );
          });
      } catch (error) {
        this.spinning = false;
      }
    },
       // 预览
    previewItem(newV) {
      this.createOrEdit(newV.item, 'preview')
    },
      /**
     * 新建修改
     */
    createOrEdit(item, type) {

      ModalHelper.create(
        CreateEdit,
        {
          customer: {
            type: type, // preview, create时不显示tab, edit
            id: item ? item.id : "",
            shortName: item ? item.shortName : "",
          }
        },
        {
          width: "1200px",
          isChange: true,
        }
      ).subscribe((res) => {
        console.log(res);
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    /**
     * 重置
     */
    refreshGoFirstPage() {
      this.filterText = "";
      this.request.skipCount = 0;
      this.getData();
    },
    /**
     * 分页事件
     */
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    /**
     * 排序更新data
     */
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.request.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    /**
     * 分页
     */
    updatePageChange(newV) {
      let { page, pageSize } = newV;
      this.pageNumber = page;
      this.request.skipCount = (page - 1) * this.request.maxResultCount;
      this.getData();
    },
    updateShowSizeChange(newV) {
      console.log(newV);
      let { curPage, maxResultCount } = newV;
      this.pageNumber = 1;
      this.request.maxResultCount = maxResultCount;
      this.getData();
    },
    /**
     * table选择事件
     */
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
    },
    /**
     * 初始化需要显示的func btn
     */
    initActionsType() {
    //   let obj = {
    //     type: "Company",
    //     isShow: true,
    //     fns: {
    //       check: { granted: this.isGranted("Pages.PersonnelFeedback.Query"), name: this.l("查看") },
    //     },
    //   };
    //   this.actionsType = obj;
    },
  },
};
</script>

<style lang="less" scoped>
.btn--container {
  margin-bottom: 20px;
}
.btn--footer {
  // border: 1px solid #e8e8e8;
  // margin: 20px;
  // padding: 20px;
  .table-delete {
    margin-left: 10px;
    margin-right: 10px;
  }
}
.btn--footer {
  margin-bottom: 20px;
}
</style>
